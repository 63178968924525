<template>
  <div class="agreement">
    <Nav />
    <div class="content flex-col">
      <p class="title">可搭吖社区规范</p>
      <p class="title-desc">更新日期：2024年7月6日</p>
      <p class="title-desc mb28">生效日期：2024年7月15日</p>
      <p class="paragraph">欢迎加入可搭吖社区！为营造真实、积极、多元的社区氛围，请大家共同遵守以下准则，维护良好的社区环境。</p>

      <p class="paragraph-title">一. 核心价值观</p>
      <p class="paragraph">可搭吖鼓励用户发布符合社会主流价值观的内容，创建一个尊重、互助、友好的社区。<br>1. 遵守国家宪法和法律法规<br>2. 践行社会主义核心价值观<br>3.
        弘扬爱国主义、集体主义和社会主义精神<br>4. 传播正确的历史观、民族观、国家观和文化观<br>5. 传承中华民族优秀传统文化<br>6. 尊重社会公德、职业道德、家庭美德和个人美德<br>7.
        弘扬科学精神、普及科学知识<br>8. 倡导积极健康的生活方式和时代风尚</p>

      <p class="paragraph-title">二. 法律法规</p>
      <p class="paragraph">对于违反法律法规、危害国家和社会安全的行为，可搭吖将采取最严格的管理措施。</p>
      <p class="paragraph"><span class="paragraph-index">2.1</span> 遵守基本准则</p>
      <p class="paragraph">可搭吖禁止发布和传播以下内容：<br>1. 违反宪法基本原则<br>2. 危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一<br>3.
        损害国家荣誉和利益，如损毁国旗国徽形象<br>4. 煽动民族仇恨、民族歧视，破坏民族团结<br>5. 破坏国家宗教政策，宣扬邪教和迷信<br>6. 散布谣言，扰乱社会秩序，破坏社会稳定<br>7.
        散布淫秽、色情、赌博、暴力、凶杀、恐怖或教唆犯罪<br>8. 侮辱或诽谤他人，侵害他人合法权益<br>9. 宣扬恐怖主义、极端主义或煽动实施恐怖活动<br>10.
        侮辱或滥用英烈形象，否定或歪曲英烈事迹，美化侵略者和侵略战争<br>11. 攻击诋毁党和国家领导人，捏造领导人负面信息，滥用领导人形象<br>12. 违背公序良俗的内容，如低俗婚闹等<br>13.
        其他法律、行政法规禁止的内容</p>
      <p class="paragraph"><span class="paragraph-index">2.2</span> 保护未成年人权益</p>
      <p class="paragraph">为更好地保护未成年人的合法权益，我们对不利于未成年人身心健康的内容严肃处理，包括但不限于以下内容：<br>1. 涉及未成年人色情低俗内容，如展示未成年人婚育、性侵害等<br>2.
        涉及未成年人暴力欺凌内容，如施暴犯罪、残害体罚、校园欺凌等<br>3. 涉及披露未成年人隐私的内容，如展示未成年人性器官、公开个人隐私等<br>4. 涉及未成年人不良行为的内容，如宗教传授、饮酒吸烟、邪典动画等<br>5.
        涉及规避未成年人系统监管的方法，如故意规避防沉迷、青少年模式等<br>6. 其他危害未成年人安全和健康的内容</p>
      <p class="paragraph"><span class="paragraph-index">2.3</span> 抵制违法信息</p>
      <p class="paragraph">可搭吖禁止传播违法内容，包括但不限于：<br>1. 涉及违禁物品的内容，如毒品、违禁药物、违法工具等<br>2. 涉及违禁活动的内容，如传销、胎儿性别鉴定、售卖假货等<br>3.
        发布欺诈信息，如假冒官方客服、虚假中奖信息等<br>4. 提供赌博交易平台信息或赌博性质服务，如赌博平台链接、游戏币回收等<br>5. 涉及赌博技巧、赌博器具等内容，如老虎机、透视扑克等</p>
      <p class="paragraph"><span class="paragraph-index">2.4</span> 抵制色情低俗内容</p>
      <p class="paragraph">可搭吖禁止发布和传播以下色情低俗内容：<br>1. 直接暴露和描写人体性部位<br>2. 表现或隐晦表现性行为、具有挑逗性或侮辱性的内容<br>3.
        以性暗示、性挑逗的语言描述性行为、性过程、性方式的内容<br>4. 全身或隐私部位未着衣物，仅用肢体掩盖隐私部位的内容<br>5. 侵犯个人隐私的走光、偷拍、漏点等内容<br>6.
        以庸俗和挑逗性标题吸引点击的内容<br>7. 相关部门禁止传播的色情和有伤社会风化的文字、音视频内容<br>8. 传播一夜情、换妻、性虐待等的有害信息<br>9. 情色动漫、小说<br>10.
        宣扬暴力、恶意谩骂、侮辱他人等的内容<br>11. 非法的性药品广告和性病治疗广告<br>12. 恶意传播侵害他人隐私的内容<br>13. 推介淫秽色情网站和低俗信息的链接、图片、文字等内容</p>

      <p class="paragraph-title">三. 活动发布及交易行为</p>
      <p class="paragraph">在可搭吖发布活动和交易信息时，必须确保内容合法、真实，不侵害他人权益或扰乱市场秩序。</p>
      <p class="paragraph"><span class="paragraph-index">3.1</span> 活动发布</p>
      <p class="paragraph">1. 确保活动内容真实、合法，不涉及欺诈或误导<br>2. 禁止发布高风险活动，如非法聚集、危险行为等<br>3. 避免发布涉及敏感政治话题或违法内容的活动</p>
      <p class="paragraph"><span class="paragraph-index">3.2</span> 交易行为</p>
      <p class="paragraph">1. 侵害消费者权益的行为，如假冒伪劣商品、侵害他人账户财务、恶意骚扰等<br>2. 扰乱市场秩序的行为，如虚假宣传、虚假交易、价格欺诈、侵犯知识产权等<br>3.
        发布高风险类的内容，如医美整形、医疗器械等<br>4. 发布其他具有营销倾向的内容，如代购、转卖、拼单等</p>

      <p class="paragraph-title">四. 用户动态</p>
      <p class="paragraph">在发布个人动态时，请确保内容积极、友好，不侵犯他人权益或造成不良影响。</p>
      <p class="paragraph"><span class="paragraph-index">4.1</span> 友好互动</p>
      <p class="paragraph">1. 避免使用侮辱性、歧视性语言或人身攻击<br>2. 尊重他人的意见和隐私，不传播虚假或未经证实的信息<br>3. 避免在动态中泄露他人的个人信息</p>
      <p class="paragraph"><span class="paragraph-index">4.2</span> 文明发布</p>
      <p class="paragraph">1. 使用文明用语，避免粗言秽语<br>2. 动态内容应积极健康，不传播低俗、暴力或违法内容<br>3. 避免在动态中引发不必要的争吵和冲突</p>

      <p class="paragraph-title">五. 用户聊天规范</p>
      <p class="paragraph">在聊天过程中，可搭吖用户应遵守以下规范，确保交流环境友好和谐：</p>
      <p class="paragraph"><span class="paragraph-index">5.1</span> 尊重他人</p>
      <p class="paragraph">1. 避免使用侮辱性、歧视性语言或人身攻击<br>2. 尊重他人的意见和隐私，不传播虚假或未经证实的信息<br>3. 避免在公共聊天中泄露他人的个人信息</p>
      <p class="paragraph"><span class="paragraph-index">5.2</span> 保持礼貌</p>
      <p class="paragraph">1. 使用文明用语，避免粗言秽语<br>2. 交流时保持礼貌和耐心，不以言语威胁或恐吓他人<br>3. 避免在聊天中引发不必要的争吵和冲突</p>
      <p class="paragraph"><span class="paragraph-index">5.3</span> 遵守法律</p>
      <p class="paragraph">1. 不在聊天中发布违法信息或进行违法活动的讨论<br>2. 不传播违反国家法律法规的内容，包括但不限于淫秽、色情、赌博、暴力等信息<br>3. 不利用聊天功能进行欺诈或其他违法行为
      </p>
      <p class="paragraph"><span class="paragraph-index">5.4</span> 避免滥用</p>
      <p class="paragraph">1. 不频繁发送广告或无关信息，避免打扰他人<br>2. 不通过聊天功能传播恶意链接或病毒<br>3. 不利用聊天功能进行任何形式的骚扰或恶意引导</p>

      <p class="paragraph-title">六. 不当行为</p>
      <p class="paragraph">可搭吖不鼓励发布不友好、欺骗或危险行为的内容，禁止任何作弊信息及行为，包括但不限于：</p>
      <p class="paragraph"><span class="paragraph-index">6.1</span> 不友好/不合适的内容</p>
      <p class="paragraph">1. 发布含人身攻击、骚扰他人的内容，如辱骂、侮辱、恶意引战等<br>2. 发布含不文明的行为，如禁烟场所吸烟、妨碍导盲犬、随地吐痰等<br>3.
        发布严重影响用户体验的内容，如血腥恐怖、标题党、图文不符等<br>4. 分享无相关性、无真实体验的产品和内容，过分参照商家指引或使用过多官方宣传语句<br>5. 发布其他不符合大众审美的内容</p>
      <p class="paragraph"><span class="paragraph-index">6.2</span> 欺骗行为</p>
      <p class="paragraph">1. 编造公众人物的谣言，如绝症、去世等不实信息<br>2. 发布违背科学常识的内容，如食品安全、健康养生领域的虚假信息</p>
      <p class="paragraph"><span class="paragraph-index">6.3</span> 危险行为</p>
      <p class="paragraph">1. 易引人模仿的危险行为，如危险驾驶、不当使用明火、干扰电梯等<br>2. 无安全提示或防护措施的危险行为，如无提示的极限运动、高空行为等</p>
      <p class="paragraph"><span class="paragraph-index">6.4</span> 作弊行为</p>
      <p class="paragraph">1. 恶意注册账号，如批量注册等<br>2. 滥用权益注册或开设多个账号，破坏或干扰平台秩序<br>3. 谋取不正当利益、损害他人权益或干扰平台正常运行的行为，如恶意举报等</p>

      <p class="paragraph-title">七. 侵权行为</p>
      <p class="paragraph">可搭吖禁止一切侵犯他人合法权益的行为，包括但不限于以下内容：</p>
      <p class="paragraph"><span class="paragraph-index">7.1</span> 姓名权、肖像权、名誉权及隐私权</p>
      <p class="paragraph">1. 未经授权使用信息，冒充他人注册账号或使用他人名称、头像等<br>2. 通过发布不实言论，散播虚假信息，损害他人名誉、商誉<br>3.
        未经授权发布他人身份证号码、家庭住址、病史、财产信用状况等个人隐私资料</p>
      <p class="paragraph"><span class="paragraph-index">7.2</span> 知识产权及其他权利</p>
      <p class="paragraph">1. 未经授权，发布内容侵犯他人著作权，如视频、照片、图文等<br>2. 侵犯他人已注册的企业名称、商标、商号、商品装潢等<br>3.
        未经授权，发布内容侵犯他人专利权，如许诺销售等<br>4. 未经授权披露商业机密或其他保密信息<br>5. 发布内容传播假冒伪劣商品信息</p>

      <p class="paragraph-title">八. 违规处理</p>
      <p class="paragraph"><span class="paragraph-index">8.1</span> 认定标准</p>
      <p class="paragraph">符合以下任一情况的，可认定为违规：</p>
      <p class="paragraph">1. 人工排查发现违反本准则<br>2. 平台算法系统排查数据后，抓取到异常数据</p>
      <p class="paragraph"><span class="paragraph-index">8.2</span> 处理措施</p>
      <p class="paragraph">可搭吖会根据违规性质和严重程度，采取以下一项或几项措施：</p>
      <p class="paragraph">1. 内容处理：扣除作弊数据，禁止展示，依法追究法律责任<br>2. 账号处理：限制账号功能，限制展示范围，账号禁言，账号封禁，依法追究法律责任</p>
      <p class="paragraph">感谢您对可搭吖社区的支持和理解！希望大家共同努力，营造一个友好、安全、积极的社区环境。</p>
    </div>
    <goTop/>
    <Footer ref="footer"></Footer>
  </div>
</template>

<script>
import Nav from '@/components/Nav'
import Footer from '@/components/Footer'
import goTop from './components/goTop.vue'
export default {
  name: "Standard",
  components: { Nav, Footer, goTop },
  data() {
    return {
    };
  },
  mounted() {

  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.agreement {
  .content {
    width: 1280rem;
    margin: 0 auto;
    padding: 148rem 0 168rem 0;

    .title {
      width: 100%;
      text-align: center;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      font-size: 36rem;
      color: #0B162B;
      line-height: 42rem;
      text-align: center;
      font-style: normal;
      text-transform: none;
      margin-bottom: 80rem;
    }

    .title-desc,
    .paragraph {
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      font-size: 20rem;
      color: #666666;
      line-height: 34rem;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    .paragraph {
      margin-bottom: 20rem;

      .paragraph-index {
        color: #333333;
      }

      .paragraph-btn {
        color: #0366d6;
        text-decoration: underline;
        word-break: break-all;
      }
    }

    .paragraph-title {
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      font-size: 20rem;
      color: #333333;
      line-height: 30rem;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-top: 16rem;
      margin-bottom: 20rem;
    }

    .bold {
      font-weight: bold;
      color: #333333
    }
  }
}
</style>
